<template>
  <base-label
    :legend="placeholder ?? ''"
    :labelType="labelType"
    :errors="errors"
    :is-active="isActive"
    :is-disabled="isDisabled"
    :is-loading="isLoading || isInternalLoading"
    :disable-disabled-opacity="disableDisabledOpacity"
    :feedback="feedback"
    :has-value="!showNotSet"
    @click="activate"
    @keydown.enter="activate"
    @focus="
      () => {
        if (activateOnFocus) {
          activate();
        }
      }
    "
    ref="input"
  >
    <div class="input-duration">
      <span v-show="showNotSet" class="input-duration__not-set">
        {{ translations.duration.notSpecified }}
      </span>
      <div v-show="!showNotSet" class="input-duration__inputs">
        <span v-if="isNegative" class="input-duration__negative">-</span>
        <div class="input-duration__group" @click="inputDays?.focus()">
          <span
            :contenteditable="!isDisabled"
            :tabindex="!isActive ? -1 : 0"
            class="input-duration__input"
            @keydown.stop="(e) => setDays(e)"
            @focus="selectText(inputDays) && setIsActive(true)"
            @blur="setIsActive(false)"
            ref="inputDays"
            v-input-direction="{
              isEnd: () => inputHours?.focus(),
            }"
          />
          <span class="input-duration__unit">{{ translations.duration.days }}</span>
        </div>
        <div class="input-duration__group" @click="inputHours?.focus()">
          <span
            :contenteditable="!isDisabled"
            :tabindex="!isActive ? -1 : 0"
            class="input-duration__input"
            @keydown.stop="(e) => setHours(e)"
            @focus="selectText(inputHours) && setIsActive(true)"
            @blur="setIsActive(false)"
            ref="inputHours"
            v-input-direction="{
              isStart: () => inputDays?.focus(),
              isEnd: () => inputMinutes?.focus(),
            }"
          />
          <span class="input-duration__unit">{{ translations.duration.hours }}</span>
        </div>
        <div class="input-duration__group" @click="inputMinutes?.focus()">
          <span
            :contenteditable="!isDisabled"
            :tabindex="!isActive ? -1 : 0"
            class="input-duration__input"
            @keydown.stop="(e) => setMinutes(e)"
            @focus="selectText(inputMinutes) && setIsActive(true)"
            @blur="setIsActive(false)"
            ref="inputMinutes"
            v-input-direction="{
              isStart: () => inputHours?.focus(),
            }"
          />
          <span class="input-duration__unit">{{ translations.duration.minutes }}</span>
        </div>
      </div>
      <icon-toggle-button
        v-if="canClear && !isDisabled && !!value"
        :tooltip="{ title: translations.inputs.clear }"
        :action="
          (e) => {
            e.stopPropagation();
            updateValue(undefined);
          }
        "
        icon="close"
        button-size="medium"
        tabindex="0"
        class="input-duration__clear"
      />
      <template v-if="addDatetimePicker && !isDisabled">
        <icon-toggle-button
          :action="
            () => {
              isOpen = true;
              datetime = getTime(true).add(value || 0, 'minute');
              min = getTime(true);
            }
          "
          button-size="medium"
          icon="today"
        />

        <AppTeleport v-if="isOpen">
          <datetime-picker
            :datetime="datetime"
            :min="min"
            :title="datetimePickerTitle"
            @update="(date) => updateValue(getDuration({ from: getTime(true), to: date }).getMinutes())"
            @close="
              () => {
                isOpen = false;
              }
            "
            ref="picker"
            v-stick-to="{
              align: 'right',
              stick: 'left',
              placement: 'bottom',
              stickTo: input,
              element: picker,
            }"
            v-lock-focus="true"
            v-click-outside="{
              callback: () => (isOpen = false),
            }"
          />
        </AppTeleport>
      </template>
    </div>
  </base-label>
</template>

<script lang="ts" setup>
import { computed, ref, watch } from "vue";

import { AppTeleport } from "@horizon56/bootstrap";
import { vClickOutside } from "@horizon56/directives/click-outside";
import { vInputDirection } from "@horizon56/directives/input-direction";
import { vLockFocus } from "@horizon56/directives/lock-focus";
import { vStickTo } from "@horizon56/directives/stick-to";
import { DateTime, getDuration, getTime } from "@horizon56/time";
import { delayFn, isNumber } from "@horizon56/utils";

import { translations } from "@/infrastructure/translations";

import IconToggleButton from "@/components/buttons/icon-toggle-button.vue";
import DatetimePicker from "@/components/date-and-time/datetime-picker/datetime-picker.vue";
import BaseLabel, { PublicProps } from "@/components/inputs/base-label.vue";
import { FeedbackReturnType } from "@/components/inputs/input-feedback.vue";

type DuationInMinutes = number;

const props = withDefaults(
  defineProps<
    PublicProps & {
      placeholder?: string;
      value?: DuationInMinutes;
      canClear?: boolean;
      addDatetimePicker?: boolean;
      datetimePickerTitle?: string;
      activateOnFocus?: boolean;
      update?: (d?: DuationInMinutes) => FeedbackReturnType;
    }
  >(),
  {
    activateOnFocus: true,
  },
);

const emit = defineEmits<{
  (e: "update", a?: DuationInMinutes): void;
}>();

const days = ref<number>(-1);
const hours = ref<number>(-1);
const minutes = ref<number>(-1);

const inputDays = ref<HTMLInputElement>();
const inputHours = ref<HTMLInputElement>();
const inputMinutes = ref<HTMLInputElement>();

const datetime = ref<DateTime>();
const min = ref<DateTime>();

const isActive = ref(false);
const isOpen = ref(false);
const input = ref();
const picker = ref();
const showNotSet = computed(() => !isActive.value && props.value === undefined);

const isInternalLoading = ref(false);
const feedback = ref<PublicProps["feedback"]>();

const isActiveTimer = ref();

const setIsActive = (is: boolean) => {
  if (props.isDisabled) {
    return;
  }
  window.clearTimeout(isActiveTimer.value);
  if (is) {
    isActive.value = is;
  } else {
    isActiveTimer.value = window.setTimeout(() => (isActive.value = false), 250);
  }
};

const selectText = (elem?: HTMLInputElement) => {
  if (props.isDisabled) {
    return;
  }
  if (elem) {
    if (window.getSelection && document.createRange) {
      const range = document.createRange();
      range.selectNodeContents(elem);
      const sel = window.getSelection();
      if (sel) {
        sel.removeAllRanges();
        sel.addRange(range);
      }
    }
  }
  return true;
};

const activate = async () => {
  if (props.isDisabled || isActive.value || isOpen.value) {
    return;
  }
  if (isNegative.value) {
    days.value = 0;
    hours.value = 0;
    minutes.value = 0;
  }

  const input =
    days.value > 0
      ? inputDays.value
      : hours.value > 0
      ? inputHours.value
      : minutes.value > 0
      ? inputMinutes.value
      : inputDays.value;

  isActive.value = true;
  await delayFn(() => !!input);
  input?.focus();
};

const isValidKey = (key: any, currentValue: number) =>
  isNumber(Number(key)) ||
  ["Backspace", "Enter", "ArrowLeft", "ArrowRight", "Enter", "Tab", "ArrowDown", "ArrowUp"].includes(key) ||
  (currentValue === 0 && key === "-");

const bumpValue = (key: string, currentValue: number) =>
  key === "ArrowUp" ? currentValue + 1 : key === "ArrowDown" && currentValue > 0 ? currentValue - 1 : currentValue;

const setDays = (e: KeyboardEvent) => {
  if (isValidKey(e.key, days.value)) {
    if (e.key === "Enter") {
      blur();
      return;
    }
    days.value = bumpValue(e.key, days.value);
  } else {
    e.stopPropagation();
    e.preventDefault();
  }
};

const setHours = (e: KeyboardEvent) => {
  if (isValidKey(e.key, hours.value)) {
    if (e.key === "Enter") {
      blur();
      return;
    }
    hours.value = bumpValue(e.key, hours.value);
  } else {
    e.stopPropagation();
    e.preventDefault();
  }
};

const setMinutes = (e: KeyboardEvent) => {
  if (isValidKey(e.key, minutes.value)) {
    if (e.key === "Enter") {
      blur();
      return;
    }
    minutes.value = bumpValue(e.key, minutes.value);
  } else {
    e.stopPropagation();
    e.preventDefault();
  }
};

const blur = () => {
  inputDays.value?.blur();
  inputHours.value?.blur();
  inputMinutes.value?.blur();
  window.getSelection()?.empty();
  window.getSelection()?.removeAllRanges();
};

const isNegative = computed(() => isNumber(props.value) && props.value < 0 && !isActive.value);

const setValues = (durationInMinutes?: number) => {
  if (isNumber(durationInMinutes)) {
    const duration = Math.abs(durationInMinutes);
    days.value = Math.floor((Math.abs(duration) ?? 0) / 24 / 60) * (duration < 0 ? -1 : 1);
    hours.value = Math.floor(((Math.abs(duration) ?? 0) / 60) % 24) * (duration < 0 ? -1 : 1);
    minutes.value = Math.floor((Math.abs(duration) ?? 0) % 60) * (duration < 0 ? -1 : 1);
  } else {
    days.value = 0;
    hours.value = 0;
    minutes.value = 0;
  }
};

const updateValues = () => {
  const _days = Number(inputDays.value?.innerText);
  const _hours = Number(inputHours.value?.innerText);
  const _minutes = Number(inputMinutes.value?.innerText);

  if (_days + _hours + _minutes === 0) {
    updateValue(undefined);
  } else {
    updateValue(_days * 24 * 60 + _hours * 60 + _minutes);
  }
};

const updateValue = async (d?: DuationInMinutes) => {
  if (d === props.value) {
    return;
  }
  emit("update", d);
  if (typeof props.update === "function") {
    isInternalLoading.value = true;
    const result = await props.update(d);
    if (result && "status" in result) {
      feedback.value = {
        ...result,
        callback: () => {
          feedback.value = undefined;
          result.callback?.();
        },
      };
    }
    isInternalLoading.value = false;
  }
};

watch(
  () => props.value,
  () => {
    if (!isActive.value) {
      setValues(props.value);
    }
  },
  { immediate: true },
);

watch(
  () => isActive.value,
  (is) => {
    if (!is) {
      updateValues();
    }
  },
);

watch(
  () => [days.value, hours.value, minutes.value],
  async ([d, h, m]) => {
    if (!inputDays.value || !inputHours.value || !inputMinutes.value) {
      await delayFn(() => !!inputDays.value && !!inputHours.value && !!inputMinutes.value);
    }
    if (!inputDays.value || !inputHours.value || !inputMinutes.value) {
      return;
    }
    inputDays.value.innerHTML = d.toString();
    inputHours.value.innerHTML = h.toString();
    inputMinutes.value.innerHTML = m.toString();
  },
  { immediate: true, deep: true },
);
</script>
<style lang="scss" scoped>
@import "./mixins";
.input-duration {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;

  height: 100%;
  flex-grow: 1;

  &__inputs {
    display: flex;
    flex-flow: row nowrap;
    height: 100%;
    margin-right: auto;
  }
  &__group {
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0 3px;
  }
  &__unit,
  &__not-set {
    user-select: none;
  }

  &__not-set {
    color: var(--black-50);
    margin-right: auto;
  }

  &__input {
    outline: none;
    white-space: nowrap;
    min-width: 5px;
    padding-right: 2px;
  }

  &__unit {
    width: min-content;
    z-index: 1;
  }

  &__negative {
    margin-right: 4px;
    justify-content: center;
    display: inline-flex;
    justify-content: center;
    flex-flow: column;
  }
}
</style>
